import { gql } from "@apollo/client";

export default {
  getMasterListFilters: gql`
    query GetMasterListFilters($ids: [String!]!, $clientId: BigInt) {
      filters: getMasterListFilters(ids: $ids, clientId: $clientId) {
        id
        type
        suggest
        title
        values {
          id
          value
        }
      }
    }
  `,
};
