import { gql } from "@apollo/client";

export default gql`
  query GetPdpStatsFilter($dates: DateRangeInput!, $id: String!) {
    getPdpStatsFilter(dates: $dates, id: $id) {
      id
      type
      suggest
      title
      values {
        id
        value
        description
      }
    }
  }
`;
