import { gql } from "@apollo/client";

export default {
  getPdpFilters: gql`
    query GetPdpStatsFilters($dates: DateRangeInput!, $ids: [String!]!) {
      filters: getPdpStatsFilters(dates: $dates, ids: $ids) {
        id
        type
        suggest
        title
        values {
          id
          value
          description
        }
      }
    }
  `,
};
