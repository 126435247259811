export const getLocalFilters = (pageHash: string) => {
  const filters = localStorage?.getItem("filters");

  if (!filters) {
    return;
  }

  const parsedFilters = JSON.parse(filters);

  return parsedFilters[pageHash];
};

export const setLocalFilters = (
  pageHash: string,
  dynamicFilters: Record<string, string | string[]>
) => {
  const filters = localStorage?.getItem("filters") || "{}";
  const parsedFilters = JSON.parse(filters);
  Object.keys(dynamicFilters).forEach((key) => {
    if (!dynamicFilters[key].length) {
      delete dynamicFilters[key];
    }
  });
  parsedFilters[pageHash] = dynamicFilters;

  localStorage.setItem("filters", JSON.stringify(parsedFilters));
};

export const clearLocalFilters = (pageHash: string) => {
  const filters = localStorage.getItem("filters");

  if (filters) {
    const parsedFilters = JSON.parse(filters);

    if (parsedFilters[pageHash]) {
      delete parsedFilters[pageHash];

      localStorage.setItem("filters", JSON.stringify(parsedFilters));
    }
  }
};

export const clearFiltersStorage = () => {
  localStorage.removeItem("filters");
};
