import { gql } from "@apollo/client";

export default {
  getSerpPdpFilters: gql`
    query GetSerpStatsFilters($dates: DateRangeInput!, $ids: [String!]!) {
      filters: getSerpStatsFilters(dates: $dates, ids: $ids) {
        id
        type
        suggest
        title
        values {
          id
          value
          description
        }
      }
    }
  `,
};
