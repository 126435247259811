export const nullStringToValueInFilters = (dynamicFilters: any) => {
  const result: any = { ...dynamicFilters };

  // eslint-disable-next-line no-restricted-syntax
  for (const key in result) {
    if (Object.prototype.hasOwnProperty.call(result, key)) {
      result[key] = Array.isArray(result[key])
        ? result[key].map((value: any) => (value === "null" ? null : value))
        : result[key] === "null"
        ? null
        : result[key];
    }
  }

  return result;
};
