import { useSelector } from "react-redux";
import { gql, useQuery } from "@apollo/client";

import { getFiltersFromDynamicFilters } from "@/modules/shared/utils";

import type { TProduct } from "@/modules/shared/types";
import type { TState } from "@/store/types";

export type TResponse<T extends Partial<TProduct>> = {
  getProducts: {
    products: T[];
    pagination: {
      limit: number;
      page: number;
      total: number;
      pages: number;
    };
  };
};

type TPagination = {
  limit: number;
  page: number;
};

type TSort = {
  by: string;
  order: string;
};

const useReviews = <T extends Partial<TProduct> = TProduct>(
  pagination: TPagination,
  sort: TSort,
  productParams: (keyof TProduct)[],
  skip: boolean,
  clientId?: number,
  type: "review" | "question" = "review"
) => {
  const {
    date,
    popupDate,
    isPopup,
    dynamicFilters,
    popupDynamicFilters,
    searchText,
    popupSearchText,
  } = useSelector((state: TState) => state.filters);

  const GET_REVIEWS = gql`
      query GetReviews(
          $dates: DateRangeInput!
          $filters: [FilterInput!]!
          $pagination: PaginationInput!
          $sort: ReviewSortingInput!
          $searchText: String
          $searchFields: [ReviewSearchFieldEnum!]
          $clientId: BigInt
      ) {
          getProducts: getReviews(
              dates: $dates
              filters: $filters
              pagination: $pagination
              sort: $sort
              searchText: $searchText
              searchFields: $searchFields
              clientId: $clientId
          ) {
              products: reviews {
                uniqIdReview
                ${productParams.join("\n")}
              }
              pagination {
                  limit
                  page
                  total
                  pages
              }
          }
      }
  `;

  const dates = {
    from: (isPopup ? popupDate : date).startDate?.format("YYYY-MM-DD") || "",
    to: (isPopup ? popupDate : date).endDate?.format("YYYY-MM-DD") || "",
  };

  const filters =
    getFiltersFromDynamicFilters(
      isPopup ? popupDynamicFilters : dynamicFilters
    ) || [];

  filters.push({
    id: "reviewType",
    values: [type],
  });

  const { loading, error, data } = useQuery<
    TResponse<T & Pick<TProduct, "spid">>
  >(GET_REVIEWS, {
    variables: {
      dates,
      filters,
      pagination,
      sort,
      searchText: isPopup ? popupSearchText : searchText,
      searchFields: ["TEXT", "USER_NAME", "TITLE"],
      clientId,
    },
    skip,
    fetchPolicy: "no-cache",
  });

  return { loading, error, data, date, dynamicFilters };
};

export { useReviews };
