import dayjs from "dayjs";

import type { TResponse } from "@/api/filters/types";
import type { TDateFilter, TFilterState } from "@/store/filters/types";

export const reducers = {
  setDateFilter(state: TFilterState, action: { payload: TDateFilter }) {
    if (action.payload.startDate && action.payload.endDate) {
      state.date = action.payload;
    } else {
      state.date = {
        startDate: dayjs(),
        endDate: dayjs(),
      };
    }
  },

  setDynamicFilters(
    state: TFilterState,
    action: { payload: Record<string, string | string[]> }
  ) {
    Object.entries(action.payload).forEach(([key, value]) => {
      state.dynamicFilters[key] = value;
    });
  },

  setDynamicFilter(
    state: TFilterState,
    action: { payload: { key: string; value: string | string[] } }
  ) {
    if (action.payload.value && action.payload.value.length) {
      state.dynamicFilters[action.payload.key] = action.payload.value;
    } else {
      delete state.dynamicFilters[action.payload.key];
    }
  },

  removeDynamicFilter(
    state: TFilterState,
    action: { payload: { key: string } }
  ) {
    delete state.dynamicFilters[action.payload.key];
  },

  resetDynamic(state: TFilterState) {
    state.dynamicFilters = {};
  },
  setFiltersData(
    state: TFilterState,
    action: { payload: TResponse | undefined }
  ) {
    state.filtersData = action.payload;
  },
  setSearchText(state: TFilterState, action: { payload: string | undefined }) {
    state.searchText = action.payload;
  },

  setPopupDateFilter(state: TFilterState, action: { payload: TDateFilter }) {
    if (action.payload.startDate && action.payload.endDate) {
      state.popupDate = action.payload;
    } else {
      state.popupDate = {
        startDate: dayjs(),
        endDate: dayjs(),
      };
    }
  },

  setPopupDynamicFilters(
    state: TFilterState,
    action: { payload: Record<string, string | string[]> }
  ) {
    Object.entries(action.payload).forEach(([key, value]) => {
      state.popupDynamicFilters[key] = value;
    });
  },

  setPopupDynamicFilter(
    state: TFilterState,
    action: { payload: { key: string; value: string | string[] } }
  ) {
    if (action.payload.value && action.payload.value.length) {
      state.popupDynamicFilters[action.payload.key] = action.payload.value;
    } else {
      delete state.popupDynamicFilters[action.payload.key];
    }
  },

  removePopupDynamicFilter(
    state: TFilterState,
    action: { payload: { key: string } }
  ) {
    delete state.popupDynamicFilters[action.payload.key];
  },

  resetPopupDynamic(state: TFilterState) {
    state.popupDynamicFilters = {};
  },
  setPopupFiltersData(
    state: TFilterState,
    action: { payload: TResponse | undefined }
  ) {
    state.popupFiltersData = action.payload;
  },
  setPopupSearchText(
    state: TFilterState,
    action: { payload: string | undefined }
  ) {
    state.popupSearchText = action.payload;
  },

  setIsPopup(state: TFilterState, action: { payload: boolean }) {
    state.isPopup = action.payload;
  },
};
