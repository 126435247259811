import { gql } from "@apollo/client";

export default {
  getSerpIndexingFilters: gql`
    query GetSerpIndexingStatsFilters(
      $dates: DateRangeInput!
      $ids: [String!]!
    ) {
      filters: getSerpIndexingStatsFilters(dates: $dates, ids: $ids) {
        id
        type
        suggest
        title
        values {
          id
          value
          description
        }
      }
    }
  `,
};
