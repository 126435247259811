import { gql } from "@apollo/client";

export default {
  getSerpFilters: gql`
    query GetSerpFilters(
      $dates: DateRangeInput!
      $ids: [String!]!
      $filters: [FilterInput!]
    ) {
      filters: getSerpFilters(dates: $dates, ids: $ids, filters: $filters) {
        id
        type
        title
        values {
          id
          value
          description
        }
      }
    }
  `,
};
