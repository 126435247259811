import axios from "axios";

import type { TRequest } from "./types";

export default {
  login: (data: TRequest) => axios.post("/login_check", data),
  registration: (data: TRequest) => axios.post("/bid/create", data),
  requestPasswordReset: (data: TRequest) =>
    axios.post("/request-password-reset", data),
  passwordReset: (data: TRequest) => axios.post("/password-reset", data),
  refreshToken: (refreshToken: string) =>
    axios.post("/token/refresh", { refresh_token: refreshToken }),
};
