import { useSelector } from "react-redux";
import { gql, useQuery } from "@apollo/client";

import { getFiltersFromDynamicFilters } from "@/modules/shared/utils";

import type { TProduct } from "@/modules/shared/types";
import type { TState } from "@/store/types";

export type TResponse<T extends Partial<TProduct>> = {
  getProducts: {
    products: T[];
    pagination: {
      limit: number;
      page: number;
      total: number;
      pages: number;
    };
  };
};

type TPagination = {
  limit: number;
  page: number;
};

type TSort = {
  by: string;
  order: string;
};

const useProducts = <T extends Partial<TProduct> = TProduct>(
  pagination: TPagination,
  sort: TSort,
  productParams: (keyof TProduct)[],
  skip: boolean,
  clientId?: number
) => {
  const {
    date,
    dynamicFilters,
    searchText,
    isPopup,
    popupDynamicFilters,
    popupSearchText,
    popupDate,
  } = useSelector((state: TState) => state.filters);

  const productParamsUpd = productParams.map((param) =>
    param === "bonusInfo" ? "bonusInfo { type, title, totalAmount}" : param
  );

  const GET_PRODUCTS = gql`
      query GetProducts(
          $dates: DateRangeInput!
          $filters: [FilterInput!]
          $pagination: PaginationInput!
          $sort: ProductSortingInput!
          $searchText: String
          $searchFields: [String!]
          $clientId: BigInt
      ) {
          getProducts(
              dates: $dates
              filters: $filters
              pagination: $pagination
              sort: $sort
              searchText: $searchText
              searchFields: $searchFields
              clientId: $clientId
          ) {
              products {
                spid
                ${productParamsUpd.join("\n")}
              }
              pagination {
                  limit
                  page
                  total
                  pages
              }
          }
      }
  `;

  const dates = {
    from: (isPopup ? popupDate : date).startDate?.format("YYYY-MM-DD") || "",
    to: (isPopup ? popupDate : date).endDate?.format("YYYY-MM-DD") || "",
  };

  const { loading, error, data } = useQuery<
    TResponse<T & Pick<TProduct, "spid">>
  >(GET_PRODUCTS, {
    variables: {
      dates,
      filters: getFiltersFromDynamicFilters(
        isPopup ? popupDynamicFilters : dynamicFilters
      ),
      pagination,
      sort,
      searchText: isPopup ? popupSearchText : searchText,
      searchFields: productParams,
      clientId,
    },
    skip,
    fetchPolicy: "no-cache",
  });

  return {
    loading,
    error,
    data,
    date: isPopup ? popupDate : date,
    // dynamicFilters: dynamicFilters,
  };
};

export { useProducts };
